.container {
  padding: 20px 20px;
  margin-top: 20px;
}

@media screen and (min-width: 900px) {
  .container {
    padding: 25px 20px 60px 20px;
    margin-top: 70px;
  }

  .auth {
    margin-left: var(--sidebar-width);
    padding-top: 50px;
  }
}

@media screen and (min-width: 1600px) {
  .container {
    padding: 25px 80px 60px 80px;
    margin-top: 100px;
  }
}