.chart {
  height: 180px;
}

.area {
  padding-bottom: 50px;
}

small {
  display: block;
  font-size: 85%;
  line-height: 1.3;
}

.headerIcon {
    color: var(--color-linkedin-blue);
}

.fans {
  font-size: 40px;
  height: 50px;

  & .icon {
    font-size: 36px;
    margin-left: 20px;
  }
}
