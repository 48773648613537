.header {
  padding-left: 20px;
  font-size: 1.2em;
}

.container {
  background-color: var(--color-white);
  padding: 0;
  margin-top: 20px;

  .tableContainer {
    overflow-x: scroll;
    padding-bottom: 20px;
  }

  .table {
    width: 100%;

    & thead {
      & td {
        border: 1px solid var(--color-light);
      }
      & tr:first-child td {
        border-top: 0;
      }
      & tr td:first-child,
      & tr th:first-child {
        border-left: 0;
      }
      & tr td:last-child,
      & tr th:last-child {
        border-right: 0;
      }
    }

    & tbody {
      & td {
        border: 1px solid var(--color-light);
      }
      & tr:first-child td {
        border-top: 0;
      }
      & tr:last-child td {
        border-bottom: 0;
      }
      & tr td:first-child,
      & tr th:first-child {
        border-left: 0;
      }
      & tr td:last-child,
      & tr th:last-child {
        border-right: 0;
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .container {
    padding: 30px;

    .tableContainer {
      overflow-x: visible;
    }
  }
}
