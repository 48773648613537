.posts {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  display: grid;
  grid-row-gap: 25px;
  grid-template-columns: 1fr;
}

.empty {
  padding-top: 40px;
  font-style: italic;
}

@media screen and (min-width: 1600px) {
  .posts {
    padding-bottom: 40px;
    column-gap: 80px;
    row-gap: 50px;
    grid-template-columns: 1fr 1fr;
  }
}
