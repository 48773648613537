.itemContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  border-top: 5px solid var(--color-green);
}

.item {
  padding: 30px 20px;
  background-color: var(--color-white);
}

.textBody {
  margin-bottom: 4rem;
}

:nth-child(5n + 2) {
  & > .itemContainer {
    border-top: 5px solid var(--color-blue);

    & > .actions {
      background-color: var(--color-pale-blue);
    }
  }
}
:nth-child(5n + 3) {
  & > .itemContainer {
    border-top: 5px solid var(--color-purple);

    & > .actions {
      background-color: var(--color-pale-purple);
    }
  }
}
:nth-child(5n + 4) {
  & > .itemContainer {
    border-top: 5px solid var(--color-orange);

    & > .actions {
      background-color: var(--color-pale-orange);
    }
  }
}
:nth-child(5n + 5) {
  & > .itemContainer {
    border-top: 5px solid var(--color-yellow);

    & > .actions {
      background-color: var(--color-pale-yellow);
    }
  }
}

.actions {
  background-color: var(--color-pale-green);
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }

  &.three {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }
}
.actions > :nth-child(1) {
  margin-left: 20px;
}
.actions > :nth-child(2) {
  margin-left: 20px;
}
.actions > div:nth-child(3) {
  position: absolute;
  right: 30px;
}
.actions > a {
  position: absolute;
  right: 70px;
}
.actions svg {
  color: var(--color-green);
}

:nth-child(5n + 2) > .itemContainer .actions svg {
  color: var(--color-blue);
}
:nth-child(5n + 3) > .itemContainer .actions svg {
  color: var(--color-purple);
}
:nth-child(5n + 4) > .itemContainer .actions svg {
  color: var(--color-orange);
}
:nth-child(5n + 5) > .itemContainer .actions svg {
  color: var(--color-yellow);
}
@media screen and (min-width: 450px) {
  .item {
    padding-top: 12px;
  }
}

@media screen and (min-width: 900px) {
  .content {
    display: grid;
    grid-template-columns: 1fr 180px;
    grid-gap: 1em;
    align-items: center;
    justify-items: end;

    .image-thumb img {
      max-width: 180px;
      max-height: 110px;
    }
  }

  &.three {
    grid-template-columns: 1fr 1fr 6fr;
    grid-gap: 1em;
  }

  .textBody {
    margin-bottom: 0;
  }

  .actions {
    padding-top: 12px;
    margin-right: 0;

    &.two {
      display: flex;
    }

    &.three {
      display: flex;
    }

    > div {
      display: inline-block;
    }

    & .icon {
      margin-bottom: -2px;
      font-size: 1.4em;
      margin-right: 10px;
    }
  }
}
