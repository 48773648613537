.container {
  margin: auto;
  margin-top: 100px;
}

.header {
  padding: 20px 40px;
  text-align: center;

  & img {
    height: 70px;
  }
}

.body {
  padding: 40px;
}

.center {
  margin-top: 30px;
  text-align: center;
}

@media screen and (min-width: 900px) {
  .container {
    background-color: var(--color-white);
    width: 400px;
    margin: auto;
    margin-top: 100px;
    box-shadow: 1px 1px var(--color-shadow-grey);
    border: solid 1px var(--color-light);
    border-radius: 5px;
  }
}
