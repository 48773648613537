.container {
  display: inline-block;
}

.action {
  color: var(--color-blue);
  cursor: pointer;
}

.text {
  margin-bottom: 40px;
}

.actions {
  text-align: right;

  & button {
    margin-right: 10px;
  }
}