.input {
  border: none;
  border-bottom: 2px solid var(--color-light-grey);
  padding: 14px 5px;
  width: 100%;
  background-color: transparent;
  transition: all 0.1s ease-in;

  &:focus {
    border-bottom: 2px solid var(--color-blue);
    transition: all 0.2s ease-in;
    outline: none;
  }
}

.error {
  color: var(--color-red);
  font-style: italic;
  padding-left: 4px;
  margin-top: 4px;
  max-width: 35rem;
  font-size: 0.8em;
  font-weight: 600;
}
