.loader {
  display: flex;
  text-align: center;
  height: 200px;
  justify-content: center;

  .pulse {
    animation: pulse 1.2s infinite;
    animation-name: pulse;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.5) rotate(-360deg);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.5);
    }
  }
}
