.ReactModal__Content {
  min-width: 30%;
  max-width: 95%;
  padding: 25px!important;
  border-radius: 5px!important;
}

.ReactModal__Overlay {
  background-color: var(--color-overlay) !important;
  z-index: 999;
}

@media screen and (min-width: 900px) {
  .ReactModal__Content {
    min-width: 30%;
    max-width: 50%;
    padding: 50px!important;
  }
}