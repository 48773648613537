.postTo {
  cursor: pointer;

  * {
    margin-right: 4px;
  }
}

.form {
  margin-top: 3rem;
}

.form > label {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.2rem;

  & span {
    font-weight: 500;
    margin-top: 5px;
  }
}

.linkedin {
  color: var(--color-linkedin-blue) !important;
}

.facebook {
  color: var(--color-facebook-blue) !important;
}

.twitter {
  color: var(--color-twitter-blue) !important;
}
