.posts {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  display: grid;
  row-gap: 30px;
  grid-template-columns: 1fr;
}

.adminActions {
  margin-left: 20px;
  margin-top: 16px;
  margin-bottom: 40px;
  font-size: 16px;

  * {
    margin-left: 4px;
  }
}

@media screen and (min-width: 1600px) {
  .posts {
    padding-bottom: 40px;
    column-gap: 80px;
    row-gap: 50px;
    grid-template-columns: 1fr 1fr;
  }
}