.item {
  background-color: var(--color-white);
  color: var(--color-grey);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  &:hover {
    text-decoration: none;
    color: var(--color-black);
  }

  & .header {
    font-weight: 600;
    padding: 10px 30px;
  }

  & .content {
    padding: 30px 30px 30px 30px;
  }
}
