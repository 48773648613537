
.picker {
  display: inline-block;
}

.picker > div {
  display: inline-block;
  width: 200px;
}

.pickerLabel {
  padding-right: 20px;
  cursor: pointer;
}