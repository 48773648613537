.container {
  margin-top: 20px;
  margin-bottom: 28px;

  & .items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 80px;
    grid-row-gap: 50px;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    margin-top: 20px;

    & .items {
      display: grid;
      column-gap: 30px;
      grid-row-gap: 20px;
    }

    & .cols2 {
      grid-template-columns: repeat(2, 1fr);
    }

    & .cols3 {
      grid-template-columns: repeat(2, 1fr);
    }

    & .cols4 {
      grid-template-columns: repeat(2, 1fr);
    }

    & .cols5 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}


@media screen and (min-width: 1600px) {
  .container {
    margin-top: 20px;

    & .items {
      display: grid;
      column-gap: 80px;
      grid-row-gap: 50px;
    }

    & .cols2 {
      grid-template-columns: repeat(2, 1fr);
    }

    & .cols3 {
      grid-template-columns: repeat(3, 1fr);
    }

    & .cols4 {
      grid-template-columns: repeat(4, 1fr);
    }

    & .cols5 {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}