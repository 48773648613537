.header {
  background-color: var(--color-white);
  height: var(--header-height);
  box-shadow: 0 1px var(--color-shadow-grey);
  display: flex;
  flex-direction: column;
  margin: 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}
.inner_header {
  display: flex;
  padding: 0 40px;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.search {
  width: 100%;
  justify-self: flex-start;
  display: flex;
  height: 100%;
  align-items: center;
}

.search_icon img {
  width: 30px;
  height: 30px;
}
.search_bar {
  width: 100%;
}
.search_bar input {
  height: 100%;
  border: 0;
  width: 100%;
  outline: none;
  font-size: 24px;
  padding: 0 30px;
}

.header_icons {
  width: 15%;
  border-right: 1px solid #ebebf2;
}
.actions {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .icon {
    font-size: 1.7em;
  }
  .dropdownName {
    font-size: 20px;
    display: none;
  }
}

@media screen and (max-width: 899px) {
  .search_bar input::placeholder {
    color: white;
    opacity: 1;
  }
}

@media screen and (min-width: 900px) {
  .header {
    z-index: 50;
    margin-left: var(--sidebar-width);
    position: fixed;
    top: 0;
    width: calc(100vw - 300px);
  }

  .actions {
    width: 100%;
    right: 0;
    width: 25%;
  }

  .search {
    width: 70%;
  }

  .dropdownName {
    display: block;
  }
}
