:root {
  --mobile-breakpoint: 450px;

  --color-dark: #1b2430;
  --color-light: #F5F5F8;

  --color-white: #fff;
  --color-black: #000;
  --color-light-grey: #C8C8C8;
  --color-lighter-grey: #f1f1f1;
  --color-grey: #707070;
  --color-blue: #2da0f9;
  --color-pale-blue: #DBE5FF;
  --color-purple: #9E59D3;
  --color-pale-purple: #EFE5FA;
  --color-green: #50d168;
  --color-pale-green: #a4e7b0;
  --color-orange: #ff9800;
  --color-pale-orange: #FFEDDB;
  --color-yellow: #f5d327;
  --color-pale-yellow: #FFF6C9;
  --color-red: red;
  --color-light-blue: #2B9FF8;
  --color-normal-blue: #178BF5;
  --color-dark-blue: #008BF5;
  --color-navy-blue: #182956;

  --color-facebook-blue: #1574eb;
  --color-linkedin-blue: #0270ad;
  --color-twitter-blue: #2aa9e0;

  --color-shadow-grey: #E0E0E0;
  --color-link-blue: #5fb4fa;

  --color-overlay: rgba(0, 0, 0, 0.50);

  --border-size: 1px;

  --sidebar-width: 300px;
  --header-height: 80px;
  --gutter: 2px;
  --box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}
