.alert {
  padding: 18px 30px;
  color: var(--color-white);
  border-radius: 5px;
}

.info {
  background-color: var(--color-blue);
}

.success {
  background-color: var(--color-green);
}

.error {
  background-color: var(--color-red);
}

.close {
  display: inline-block;
  cursor: pointer;
  float: right;
}
