.item {
  color: var(--color-link-blue);
  display: grid;
  grid-template-columns: 99fr 1fr;
  grid-gap: 1em;

  &:hover {
    text-decoration: none;
    color: var(--color-link-blue);
  }

  .icon {
    float: right;
  }

  .label {
    display: inline-block;
    overflow: hidden!important;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 85%;
  }
}

@media screen and (min-width: 781px) {
  .item {
    grid-template-columns: 7fr 1fr;
    grid-template-columns: minmax(0, 7fr) 1fr;
  }
}
