.icon {
  color: var(--color-twitter-blue);
  font-size: 12em;
  margin-bottom: 40px;
}

.buttons button {
  margin-bottom: 6px;
  min-width: 100%;
}

.button {
  color: var(--color-white);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: 800;
  min-width: 60px;
  text-transform: uppercase;

  &:disabled {
    background-color: var(--color-grey);
  }

  .loader {
    margin-left: 20px;
    display: inline-block;
  }
}

@media screen and (min-width: 900px) {
  .button {
    min-width: 120px;
  }
}
