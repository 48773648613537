.container {
  background-color: var(--color-white);
  width: 400px;
  margin: auto;
  margin-top: 100px;
  box-shadow: 1px 1px var(--color-shadow-grey);
  border: solid 1px var(--color-light);
  border-radius: 5px;
}

.header {
  padding: 20px 40px;
  text-align: center;

  & img {
    height: 70px;
  }
}

.body {
  padding: 40px;
}

.center {
  margin-top: 30px;
  text-align: center;
}

p {
  line-height: 1.6;
  margin-bottom: 2rem;
}

.start {
  margin-top: 5rem;
  margin-bottom: 3rem;
}