.input {
  height: 40px;
  width: 100%;
  max-width: 35rem;
}

.error {
  color: var(--color-red);
  font-style: italic;
  padding-left: 4px;
  margin-top: 4px;
  max-width: 35rem;
  font-size: 0.8em;
  font-weight: 600;
}
