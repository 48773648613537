@import 'styles/root';
@import 'styles/utils';

body {
  margin: 0;
  padding: 0;
  font-family: source-sans-pro, sans-serif;
  font-size: 16px;
  background-color: var(--color-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3 {
  margin: 0;
  font-size: 40px;
  color: #43425D;
}
h2 {
  font-size: 33px;
}
h3 {
  font-size: 25px;
}
p {
  color: var(--color-grey);
}
