.header {
  padding: 10px 30px;
  font-size: 1.2em;
  margin-top: 50px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-yellow);
  background-color: var(--color-pale-yellow);
  border-top: 5px solid var(--color-yellow);
}

.container {
  background-color: var(--color-white);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 28px;

  & .items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
  }
}

@media screen and (min-width: 780px) {
  .container {
    & .items {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (min-width: 1200px) {
  .container {
    & .items {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media screen and (min-width: 1800px) {
  .container {
    & .items {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
