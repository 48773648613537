.react-datepicker__portal {
  background-color:var(--color-overlay);
}
.react-datepicker__time-container {
  background-color: var(--color-purple);
}
.react-datepicker {
  font-size: 1em;
  border: 0;
  border-radius: 0;
  color: #333;
  font-family: source-sans-pro, sans-serif;
}
.react-datepicker .react-datepicker__navigation--next {
  outline: 0;
  border-left-color: white !important;
}
.react-datepicker .react-datepicker__navigation--previous {
  outline: 0;
  border-right-color: white !important;
}

.react-datepicker__header {
  background-color: #2B9FF8;
  border-radius: 0;
  border-bottom: 1px solid #f5f5f8;
}
.react-datepicker__header--time {
  color: white;
  background-color: var(--color-purple);
}
.react-datepicker__time {
  color: white; 
  background-color: var(--color-purple) !important;
}
.react-datepicker__current-month {
  font-size: 1.2em;
  color: white;
}

.react-datepicker-time__header {
  font-size: 1.2em;
  color: white !important;
}

.react-datepicker__day-name {
  width: 2.5rem !important;
  color: white;
  line-height: 2.5rem !important;
}

.react-datepicker__day {
  width: 2.5rem !important;
  line-height: 2.5rem !important;
}
.react-datepicker__day:hover {
  background-color: #f5f5f8;
}
.react-datepicker__day:focus {
  outline: 0;
}
.react-datepicker__day--selected {
  background-color: #2B9FF8;
  border: 0;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected {
  background-color: #008BF5;
}
.react-datepicker__time-list {
  height: 151px;
}

.react-datepicker__input-container input {
  width: 100%;
  border: none;
  cursor: pointer;
}

@media screen and (min-width: 900px) {
  .react-datepicker__day-name {
    width: 4rem !important;
    line-height: 4rem !important;
  }

  .react-datepicker__day {
    width: 4rem !important;
    line-height: 4rem !important;
  }

  .react-datepicker__time-list {
    height: 251px;
  }
}

@media screen and (max-width: 899px) {
  .react-datepicker__portal {
    padding-top: 35%;
    align-items: flex-start !important;
  }
}
