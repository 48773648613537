.item {
  padding: 30px 20px;
  position: relative;
  background-color: var(--color-white);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  border-left: 5px solid var(--color-purple);
  border-right: 50px solid var(--color-purple);

  .headerRow {
    display: flex;
    justify-content: space-between;

    .adminActions * {
      margin-left: 4px;
    }
  }
}
.item:nth-child(5n + 2) {
  border-left: 5px solid var(--color-blue);
  border-right: 50px solid var(--color-blue);
}
.item:nth-child(5n + 3) {
  border-left: 5px solid var(--color-green);
  border-right: 50px solid var(--color-green);
}
.item:nth-child(5n + 4) {
  border-left: 5px solid var(--color-yellow);
  border-right: 50px solid var(--color-yellow);
}
.item:nth-child(5n + 5) {
  border-left: 5px solid var(--color-orange);
  border-right: 50px solid var(--color-orange);
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;

  .image {
    text-align: center;
    padding-top: 10px;
  }
}
.actions {
  text-align: center;
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translate(0, -50%);

  > button {
    width: 30px;
    height: 30px;
    min-width: unset;
    border: none;
    padding: 0;
    background-color: transparent;
    background-image: url(/calendar-add.svg);

    & * {
      margin-left: 0 !important;
    }
  }
  > button:disabled {
    opacity: 0.5;
    background-color: transparent;
    background-image: url(/calendar-scheduled.svg);
  }
}

@media screen and (min-width: 1100px) {
  .item {
    padding: 15px 25px;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 180px;
    grid-gap: 1em;
    align-items: center;
    height: calc(100% - 40px);

    .image {
      height: 100%;
      display: flex;
      text-align: center;
      padding-top: 0;
      width: 180px;
    }

    .image img {
      max-width: 180px;
      max-height: 110px;
    }
  }
  .actions {
    text-align: right;

    > div {
      display: inline-block;
      padding-left: 24px;
    }
  }
}
