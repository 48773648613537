.button {
  color: var(--color-white);
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1em;
  font-weight: 400;
  min-width: 60px;
  border: none;
  text-transform: uppercase;
  background-color: var(--color-blue);
  box-shadow: var(--box-shadow);
  outline: none;
  
  &:disabled {
    background-color: var(--color-grey);
  }

  .loader {
    margin-left: 20px;
    display: inline-block;
  }
}

@media screen and (min-width: 900px) {
  .button {
    min-width: 120px;
  }
}
