.sidenav {
  .menuIcon {
    color: var(--color-navy-blue);
  }

  .topPanel {
    background-color: var(--color-lighter-grey);
    color: var(--color-white);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      text-align: center;
      font-size: 1.2em;
      min-width: 80px;

      a {
        color: var(--color-light);
        text-decoration: none;
      }
    }

    .toggle {
      height: 60px;
      width: 50px;
      font-size: 1.2em;
      position: absolute;
      left: 12px;
      top: 15px;
      cursor: pointer;
    }
  }

  .panel {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    top: 0;
    left: 0;
    background-color: var(--color-light-blue);
    overflow-x: hidden;
    padding-top: 5px;

    & .items {
      max-height: 0;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

      &.expanded {
        overflow: hidden;
        padding-bottom: 30px;
        max-height: 500px; /* approximate max height */
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
      }

      & .link {
        padding-left: 90px;
        text-decoration: none;
        font-size: 2rem;
        color: var(--color-white);
        display: flex;
        transition: 0.3s;
        cursor: pointer;
        min-height: 55px;
        position: relative;
        align-items: center;

        &.active {
          background: var(--color-normal-blue);
          border-left: 5px solid var(--color-purple);
          box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
        }

        &:nth-of-type(2).active {
          border-left-color: var(--color-navy-blue);
        }

        &:nth-of-type(3).active {
          border-left-color: var(--color-green);
        }

        &:nth-of-type(4).active {
          border-left-color: var(--color-yellow);
        }

        &:nth-of-type(5).active {
          border-left-color: var(--color-orange);
        }

        & .icon {
          width: 20px;
          margin-right: 14px;
          position: absolute;
          left: 50px;
          top: 50%;
          transform: translate(0, -50%);
          color: var(--color-white);
        }

        &:nth-of-type(1).active .icon {
          color: var(--color-purple);
        }

        &:nth-of-type(2).active .icon {
          color: var(--color-navy-blue);
        }

        &:nth-of-type(3).active .icon {
          color: var(--color-green);
        }

        &:nth-of-type(4).active .icon {
          color: var(--color-yellow);
        }

        &:nth-of-type(5).active .icon {
          color: var(--color-orange);
        }

        & .rightIcon {
          display: none;
        }
      }

      & .subItems {
        display: none;

        &.expanded {
          display: block;
        }

        & .link {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .sidenav {
    .topPanel {
      top: 0;
      position: fixed;
      width: var(--sidebar-width);
      height: var(--header-height);

      .logo {
        text-align: center;
        font-size: 1.2em;
      }

      .toggle {
        display: none;
      }
    }

    .panel {
      top: var(--header-height);
      height: calc(100% - var(--header-height));
      width: var(--sidebar-width);
      position: fixed;

      & .items {
        padding-top: 30px;
        max-height: 100%;

        .link {
          margin-bottom: 6px;

          & .rightIcon {
            display: block;
            position: absolute;
            margin-right: 14px;
            right: 25px;
            top: 50%;
            transform: translate(0, -50%);
            color: var(--color-white);
          }
        }
      }

      & a:hover {
        color: var(--color-blue);
      }

      & .toggle {
        display: none;
      }
    }
  }
}
