.messageBox {
    padding-bottom: var(--padding);
    padding: 20px;
    padding-left: 70px;
    margin-bottom: 40px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    background-image: url(/info-icon.svg);
    background-size: 30px 30px;
    background-position: 20px center;
    background-repeat: no-repeat;

    p {
        padding: 0;
        margin: 0;
    }
}
