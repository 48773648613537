.text-center {
  text-align: center;
}

.shadow {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.bg-purple {
  color: var(--color-purple);
  background-color: var(--color-pale-purple);
}

.bg-blue {
  color: var(--color-blue);
  background-color: var(--color-pale-blue);
}

.bg-green {
  background-color: var(--color-green);
}
.bg-orange {
  color: var(--color-orange);
  background-color: var(--color-pale-orange);
}

.bg-yellow {
  background-color: var(--color-yellow);
}

.bg-red {
  background-color: var(--color-red);
}

.color-purple {
  color: var(--color-dark);
}

.color-blue {
  color: var(--color-blue);
}

.color-green {
  color: var(--color-green);
}

.color-orange {
  color: var(--color-orange);
}

.color-yellow {
  color: var(--color-yellow);
}

.color-red {
  color: var(--color-red);
}

.border-purple {
  border-color: var(--color-purple);
}
.border-yellow {
  border-color: var(--color-yellow);
}
.border-green {
  border-color: var(--color-green);
}
.border-orange {
  border-color: var(--color-orange);
}
.border-blue {
  border-color: var(--color-blue);
}

.border-top {
  border-top-style: solid;
  border-top-width: 5px;
}
.border-left {
  border-left-style: solid;
  border-left-width: 5px;
}

.image-thumb {
  overflow: hidden;
  display: grid;
  align-content: center;
  justify-content: center;
}

.image-thumb img {
  max-width: 180px;
  max-height: 110px;
}
